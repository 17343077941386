/* You can add global styles to this file, and also import other style files */
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700;300;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&family=Rubik:wght@200;300;500&display=swap');
/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
@import 'medium-editor/dist/css/medium-editor.css';
@import 'medium-editor/dist/css/themes/bootstrap.css';
@import 'intl-tel-input/build/css/intlTelInput.css';

/** Dark theme surface colors */
$clr-surface-a0: #121212;
$clr-surface-a10: #282828;
$clr-surface-a20: #3f3f3f;
$clr-surface-a30: #575757;
$clr-surface-a40: #717171;
$clr-surface-a50: #8b8b8b;

/** Dark theme tonal surface colors */
$clr-surface-tonal-a0: #1f2324;
$clr-surface-tonal-a10: #343838;
$clr-surface-tonal-a20: #4a4d4e;
$clr-surface-tonal-a30: #616465;
$clr-surface-tonal-a40: #797c7d;
$clr-surface-tonal-a50: #939595;

$border-radius: 0.375rem;
$border-radius-sm: 0.25rem;
$border-radius-lg: 0.5rem;
$border-radius-xl: 1rem;
$border-radius-xxl: 2rem;


body {
    font-family: 'Rubik', sans-serif !important;
    font-weight: 300;    
    background-color: #f8f9fa;
}

.main-container {
  margin-top: 16px !important;
}

.dark {
  background-color: $clr-surface-a0 !important;
  color: white !important;

  .bg-white {
    background-color: $clr-surface-a10 !important;
    color: white !important;
  }

  .nav-link {
    color: white !important;
  }

  .table > :not(caption) > * > * {
    border-bottom-color: $clr-surface-a10 !important;
  }

  table {
    th {
      background-color: $clr-surface-a30 !important;
      color: white !important;
    }

    td {
      background-color: $clr-surface-a20 !important;
      color: #fbfbfb !important;
    }
  }
}

.h-100 {
  height: 100vh !important;
  overflow: hidden;
}

.btn-toggle[aria-expanded="true"]::before {
  transform: rotate(90deg);
}

.btn-toggle[aria-expanded="true"] {
  color: rgba(255, 255, 255, .85);
}

.btn-toggle::before {
  width: 1.25em;
  line-height: 0;
  content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
  transition: transform .35s ease;
  transform-origin: 0.5em 50%;
}

h1 {
  font-size: 2.3em;
  font-weight: 500;
}

h3 {
  font-size: 21px;
  font-weight: 500;
}

h3 {
  font-weight: 500;
}

h5 {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: rgba(99, 99, 99, 1.0);
  letter-spacing: 1px;
}

p {
  font-size: 14px;
  font-weight: 300;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.3);
}

a {
  color: #1009A9 !important;
  text-decoration: none;
}

span {
  font-weight: 300;
}

.text-w300 {
  font-weight: 300;
}

.text-w400 {
  font-weight: 400;
}

.text-w500 {
  font-weight: 500;
}

.btn-success {
  background-color: #00DB9C !important;
  color: rgba(0,0,0,1);
}

.card {
    h4 {
      font-size: 18px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      font-weight: 300;
    }
}

.building {
  position: fixed;
  width: 100%;
  height: 100%;    
  background-repeat: no-repeat;
  background-position: 100% 100%;
}

.form-control-label {
  font-size: 13px;
  font-weight: 400;
  color: #999;
}

.form-check-input {
  width: 3em !important;
  height: 24px;
}

.text-sm {
  font-size: 0.9em;
  font-weight: 300;
}

.avatar-container {
  position: relative;
  width: fit-content;
  left: 36%;
}

.image-container {
    height: 140px;
    width: 140px;
    z-index: 1;
    border-radius: 50%;
    background-color: #fff;
    background-position: center;
    background-size: cover;
    box-shadow: 0 6px 14px 14px rgba(0,0,0,0.04);
    overflow: hidden;
}

.icon-wrapper {
    label {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type='file'] {
        display: none;
    }
}

.icon-wrapper {
    position: absolute;
    height: 40px;
    width: 40px;
    padding: .35rem;
    background-color:#2962FF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10%;
    top: 65%;
    z-index: 2;
    cursor: pointer;

    &:hover {
        transition: all .25s;
        background-color: darken(#2962FF, 15%);
    }

    .icon {
        color: white;
    }

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    input[type='file'] {
        display: none;
    }
}

.general-wrapper {
  input[type='file'] {
    display: none;
  }

  label {
    cursor: pointer;
    border-radius: 6px;
    width: 130px;
    background-color: rgba(16, 9, 169, 0.7) !important;
    color: #fff !important;
    font-weight: 500 !important;
    padding: 10px 14px;
    text-align: center;
  }
}

.form-label {
    font-size: 14px;
    font-weight: 500;
    color: #545454;
}

.form-group.required .form-label:after {
    content:"*";
    color:red;
  }

.dropdown-toggle-alt::after {
    border: 0 none !important;
    margin-left: 0 !important;
    content: " ";
}

.dropdown-toggle::after {
    content: none;
}

.xng-breadcrumb-link {
    color: #0d6efd !important;
    text-decoration: underline !important;
    font-size: 0.85em !important;
}

.xng-breadcrumb-trail, .xng-breadcrumb-separator {
    font-size: 0.85em !important;
}

// .xng-breadcrumb-separator {
    // content: ">" !important;
// }

.fixed-bottom {
    position: fixed;
    bottom: 0;
}

.overflow-hidden {
    overflow: hidden;
}

.bg-info-300 {
    background-color: $cyan-300 !important;
}

.center-icon {
    position: absolute; top: 50%; -ms-transform: translateY(-50%); transform: translateY(-50%);
}

.badge {
    font-size: 0.75em !important;
    font-weight: 500 !important;
}

.hover-info {
    position: relative;
    background-color: white !important;
    color: $cyan-500 !important;
    &:hover {
        background-color: $cyan-500 !important;
        color: white !important;
        cursor: pointer;
    }
}

.hover-danger {
    background-color: white !important;
    color: $red-300 !important;
    &:hover {
        background-color: $red-500 !important;
        color: white !important;
        cursor: pointer;
    }

}

.w-33 {
    width: 33% !important;
}

// .nav-item {
  // color: #545454 !important;
  // &.active {}
// }


@media (max-width: 992px) {
    .main-nav {
        width: 80px !important;

        .avatar {
            width: 24px;
            height: 24px;
            overflow: hidden;
        }
    }

    .nav-link {
        padding-left: 0px !important;
    }

    .fixed-bottom {
        width: 60px;
    }
}

@media (min-width: 993px) {
    .main-nav {
        width: 320px !important;

        .avatar {
            width: 44px;
            height: 44px;
            overflow: hidden;
        }

        .username {
            font-size: 1.1em !important;
            font-weight: 500;
        }

        .nav-item {
            h4 {
                font-size: 1.1em !important;
                font-weight: bold;
            }
        }

        .nav-link {
            .btn {
                font-size: 1.1em !important;
                font-weight: 400;
            }

            span {
                font-size: 1.1em !important;
                font-weight: 400;
            }

            .nav-icon {
                top: 2px;
                position: relative;
            }
        }
    }

    .fixed-bottom {
        width: 300px;
    }

    .logout {
        font-size: 1.0em !important;
        font-weight: 400;
    }
}

@media (min-width: 1480px) {
  .main-nav {
    width: 280px !important;
  }
}

.nav-link {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 200 !important;
  color: $clr-surface-a10 !important;
  -webkit-transition: background-color 600ms linear;
  -moz-transition: background-color 600ms linear;
  -o-transition: background-color 600ms linear;
  -ms-transition: background-color 600ms linear;
  transition: background-color 600ms linear;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.list-group-item {
  h4 {
    font-size: 1.25em;
    font-weight: 700;
  }

  p {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.2;
  }
}

/* Custom Button Styles */
.btn {
  span {
    font-weight: 500 !important;
  }
}

.btn-escalas {
    background-color: #FAE271 !important; // #1009A9 !important;
    color: #1009A9 !important;
    border: 2px solid #1009A9 !important;
}

.border-escalas {
    border-color: #1009A9 !important;
}

.badge-escalas {
  font-size: 0.75em !important;
  font-weight: 500 !important;
  width: 34px !important;
  height: 34px !important;
  color: white !important;
  // background-color: #1009A9 !important;
  // color: #FAE271 !important;
}

/* END Custom Button Styles */

/* Custom Text Styles */

.text-escalas {
    color: #EBA77C;
}

/* END Custom Text Styles */

/* Editor styles */

.text-editor {
  border: 0px none !important;
  &:focus-active {
    border: 1px solid pink !important;
  }

  &:focus {
    border: 1px solid pink !important;
  }

  &:focus-visible {
    border: 1px solid pink !important;
  }
}

.text-editor-title {

    &.medium-editor-element {
        font-size: 1.7em;
        font-weight: 700;
    }

    &.medium-editor-placeholder {
    color: #999;
    font-size: 2em !important;
    font-weight: 500;
    }
}

.text-editor {

    &.medium-editor-element {
        font-size: 1.25em;
        font-weight: 300;
    }

  &.medium-editor-placeholder {
    color: #999;
    font-size: 1.25em;
    font-weight: 400;
  }

  &:focus-active {
    border: 1px solid pink !important;
  }

  &:focus {
    border: 1px solid pink !important;
  }

  &:focus-visible {
    border: 1px solid pink !important;
  }
}

/* end editor styles */

.dropzone {
  height: 130px;
  display: table;
  width: 100%;
  
  .no-media {

  }
  
  background-color: transparent !important;
  border: dashed 5px #ececec;

  input[type="file"] {
    display:none;
  }

  .text-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .centered {
    font-family: sans-serif;
    font-size: 1.2em;
    text-align:center;
  }
  
  label {
    font-size: 0.9em;
    font-weight: 500;
  }

  .textLink{
    color: blue; 
  }
}


.fileItem{
  overflow:hidden;
  height: fit-content;
  margin:3px;
  padding: 10px;
  display:block;
  position:relative;
  float:left;
  border:2px dashed #778899;
  border-radius:5px;
  transition: .3s ease;
}
    
.fileItem:hover{
  border:2px solid #c33838;
  background-color: #c33838bf;
}
    
.fileItem:hover .fileItemIcon::before{
  content: "\f00d";
  color: whitesmoke;
}
    
.fileItem:hover .fileItemText{
  color: whitesmoke;
}
  
.fileItemIconDiv{
  text-align:center;
}

.fileItemIcon::before{
  font-style: normal;
  content: "\f15b";
  color:#778899;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
  
.fileItemText{
  text-align:center;
  margin-top:10px;
  height: 40px;
}

/* Custom Card Styles */

.card-body {
  &.card-bg-secondary {
    background-color: rgb(237, 237, 237) !important;
  }
}

.data-item{
  /* 
  All you need to do this play with this number (343) according to aviable width and item width 
  Formula: (ParentWidth / 3) - margin (ParentWidth divide by 3 subtracte by margin[if you want e.g in my case i set 10] )
  
  Stackoverflow Run Code Snippet width = 633.82px (not sure)
  Margin = 10px lTRB
  
  */
  flex: 0 0 33.0%; /* play with this number */
  margin: 0.1%;
}

/* END Custom Card Styles */

/* Custom Calendar Styles */

.fc-toolbar-title {
  font-size: 1.25em !important;
  font-weight: 500;
  color: #545454;
  text-transform: uppercase;
  margin-top: 6px;
}

.fc-button-group {

  .fc-button-primary {
    background-color: rgb(235, 167, 124) !important;
    color: #2B2B2E !important;
    border: 1px solid #3E3E43 !important;
    font-size: 14px !important;
    font-weight: 300;

    .fc-icon {
      font-size: 1.0em !important;
      font-weight: 500;
    }
  }
}

.fc-col-header-cell-cushion {
    font-size: 13px;
    text-decoration: none !important;
    color: black !important;
}

.fc-daygrid-day-top {
    justify-content: center !important;
}

.fc-daygrid-day-number {
    font-size: 13px;
    font-weight: 500;
    color: #545454;
    text-decoration: none !important;
}

.fc-daygrid-day-events {

}

/* END Custom Calendar Styles */

// HORIZONTAL SCROLLBAR
.cards-container {
  max-width: 70vw;
  padding: 0 15px;
  // margin: 0 auto;
  overflow-x: scroll;
  overflow-y: hidden;
}

.cards {
  display: grid;
  grid-auto-columns: 14%;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  padding: 25px 0px;
  list-style: none;    
  scroll-snap-type: x mandatory;
  max-height: 500px;
  
  .card {
    display: flex;
    flex-direction: column;
    padding: 0px;
    background: var(--white);
    scroll-snap-align: start;
    transition: all 0.2s;
    height: 380px;
  }
  
  .card:hover {
    color: var(--white);
    background: var(--orange);
  }
  
  .card .card-title {
    font-size: 20px;
  }
  
  .card .card-content {
    margin: 20px 0;
    max-width: 85%;
  }
  
  .card .card-link-wrapper {
    margin-top: auto;
  }
  
  .card .card-link {
    display: inline-block;
    text-decoration: none;
    color: white;
    background: var(--orange);
    padding: 6px 12px;
    border-radius: 8px;
    transition: background 0.2s;
  }
  
  .card:hover .card-link {
    background: var(--darkorange);
  }
  
  .cards::-webkit-scrollbar {
    height: 12px;
  }
  
  .cards::-webkit-scrollbar-thumb,
  .cards::-webkit-scrollbar-track {
    border-radius: 92px;
  }
  
  .cards::-webkit-scrollbar-thumb {
    background: var(--darkorange);
  }
  
  .cards::-webkit-scrollbar-track {
    background: var(--thumb);
  }
  
  @media (min-width: 500px) {
    .cards {
      grid-auto-columns: calc(50% - 10px);
      grid-column-gap: 20px;
    }

    .list-group-item {
      h4 {
        font-size: 1.15em;
        font-weight: 700;
      }
    
      p {
        font-size: 0.8em;
        font-weight: 400;
        line-height: 1.1;
      }
    }
  }
  
  @media (min-width: 700px) {
    .cards {
      grid-auto-columns: calc(calc(100% / 3) - 20px);
      grid-column-gap: 30px;
    }
  }
  
  @media (min-width: 1100px) {
    .cards {
      grid-auto-columns: calc(25% - 30px);
      grid-column-gap: 40px;
    }
  }
}

.carousel-indicators {
  bottom: -42px !important;
}



/* For WebKit browsers (e.g., Chrome, Edge, Safari) */
::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 10px; /* Height for horizontal scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* Background of the track */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: #555; /* Change color on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Makes the scrollbar thinner */
  scrollbar-color: #888 transparent; /* Thumb and track colors */
}

/* Optional: Add to specific elements */
.scrollable-div {
  height: 300px;
  overflow-y: scroll;
  scrollbar-width: 4px;
  scrollbar-color: #888 transparent;
}


table {
  td {
    font-size: 14px;
    font-weight: 300;
  }
}

.selected-item {
  border: 1px solid gainsboro;
  background-color: gainsboro;
  max-width: 130px !important;
  border-radius: 6px !important;
  font-size: 14px !important;

  a {
    font-size: 14px !important;
    line-height: 1 !important;
    font-weight: 400 !important;
    padding-left: 6px !important;
    position: relative;
    top: -1px !important;
  }
}

.rounded-top-left-1 {
  border-top-left-radius: $border-radius !important;
  overflow: hidden !important;
}
.rounded-top-left-2 {
  border-top-left-radius: $border-radius-sm !important;
  overflow: hidden !important;
}
.rounded-top-left-3 {
  border-top-left-radius: $border-radius-lg !important;
  overflow: hidden !important;
}
.rounded-top-left-4 {
  border-top-left-radius: $border-radius-xl !important;
  overflow: hidden !important;
}
.rounded-top-left-5 {
  border-top-left-radius: $border-radius-xxl !important;
  overflow: hidden !important;
}

.rounded-top-right-1 {
  border-top-right-radius: $border-radius !important;
}
.rounded-top-right-2 {
  border-top-right-radius: $border-radius-sm !important;
}
.rounded-top-right-3 {
  border-top-right-radius: $border-radius-lg !important;
}
.rounded-top-right-4 {
  border-top-right-radius: $border-radius-xl !important;
}
.rounded-top-right-5 {
  border-top-right-radius: $border-radius-xxl !important;
}

.slashy {
  line-height: 1.2;
  margin: 0 0.5em;
}

.mobile-feed {
  border-radius: 2.6em !important; 
  min-width: 310px !important; 
  max-width: 310px !important;
  max-height: 680px; 
  overflow-y: scroll; 
  overflow-x: hidden; 
  position: relative; 
  z-index: 99; 
  margin-top: 35px;
}


.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 50px;
  width: 320px !important;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 0;
  flex-grow: 0;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}


.ngx-toastr {
  border-radius: 2rem !important;
}

.post-container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

textarea {
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  font-size: 16px;
}

.settings {
  margin: 10px 0;
}

.settings label {
  display: block;
  margin-bottom: 5px;
}

.settings input {
  margin-bottom: 10px;
  padding: 5px;
  font-size: 14px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

canvas {
  border: 1px solid #ccc;
  margin: 20px 0;
  width: 375px;
  height: 375px;
}

img {
  max-width: 100%;
  margin: 0px 0;
}

.thumbnail {
  width: 120px;
  height: 120px;
  border: 2px solid transparent;
  margin: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.thumbnail:hover {
  border-color: #007bff;
}

.selected {
  border-color: #28a745;
  box-shadow: 0px 0px 5px #28a745;
}

.thumb-img, .thumb-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.iti {
  width: 100% !important;
}

.iti__flag {
  background-image: url("https://raw.githubusercontent.com/jackocnr/intl-tel-input/master/build/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
.iti__flag {
    background-image: url("https://raw.githubusercontent.com/jackocnr/intl-tel-input/master/build/img/flags@2x.png");
  }
}