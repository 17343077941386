.medium-toolbar-arrow-under:after {
  border-color: #428bca transparent transparent transparent;
  top: 60px; }

.medium-toolbar-arrow-over:before {
  border-color: transparent transparent #428bca transparent; }

.medium-editor-toolbar {
  background-color: #428bca;
  border: 1px solid #357ebd;
  border-radius: 4px; }
  .medium-editor-toolbar li button {
    background-color: transparent;
    border: none;
    border-right: 1px solid #357ebd;
    box-sizing: border-box;
    color: #fff;
    height: 60px;
    min-width: 60px;
    -webkit-transition: background-color .2s ease-in, color .2s ease-in;
            transition: background-color .2s ease-in, color .2s ease-in; }
    .medium-editor-toolbar li button:hover {
      background-color: #3276b1;
      color: #fff; }
  .medium-editor-toolbar li .medium-editor-button-first {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px; }
  .medium-editor-toolbar li .medium-editor-button-last {
    border-bottom-right-radius: 4px;
    border-right: none;
    border-top-right-radius: 4px; }
  .medium-editor-toolbar li .medium-editor-button-active {
    background-color: #3276b1;
    color: #fff; }

.medium-editor-toolbar-form {
  background: #428bca;
  border-radius: 4px;
  color: #fff; }
  .medium-editor-toolbar-form .medium-editor-toolbar-input {
    background: #428bca;
    color: #fff;
    height: 60px; }
    .medium-editor-toolbar-form .medium-editor-toolbar-input::-webkit-input-placeholder {
      color: #fff;
      color: rgba(255, 255, 255, 0.8); }
    .medium-editor-toolbar-form .medium-editor-toolbar-input:-moz-placeholder {
      /* Firefox 18- */
      color: #fff;
      color: rgba(255, 255, 255, 0.8); }
    .medium-editor-toolbar-form .medium-editor-toolbar-input::-moz-placeholder {
      /* Firefox 19+ */
      color: #fff;
      color: rgba(255, 255, 255, 0.8); }
    .medium-editor-toolbar-form .medium-editor-toolbar-input:-ms-input-placeholder {
      color: #fff;
      color: rgba(255, 255, 255, 0.8); }
  .medium-editor-toolbar-form a {
    color: #fff; }

.medium-editor-toolbar-anchor-preview {
  background: #428bca;
  border-radius: 4px;
  color: #fff; }

.medium-editor-placeholder:after {
  color: #357ebd; }
